import { Badge, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { debounce } from 'lodash';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { tokenUpdate } from '../atoms/authModalControl';
import { bannerPages } from '../atoms/bannerPages';
import { user } from '../atoms/user';
import PaginatedBookSlider from '../components/BookComp/PaginatedBookSlider';
import PaginatedCoursesSlider from '../components/Courses/PaginatedCoursesSlider';
import PaginatedSchoolSlider from '../components/ResourcesPage/SchoolsPage/PaginatedSchoolSlider';
import ParserV2 from '../components/shared/basic/Parser/ParserV2';
import { ShouldRender } from '../components/shared/basic/ShouldRender';
import { formateErrorObject } from '../components/shared/formateErrorObject';
import getIdByName from '../components/shared/getIdByName';
import { getLoginToken } from '../components/shared/getToken';
import { notify } from '../components/shared/notify';
import HeroSection, { BookProps } from '../components/V2/HomePage/HeroSection';
import HomepageSlider from '../components/V2/HomePage/HomepageSlider';
import { EventDetails, logEvent } from '../hooks/useClickEventTracking';
import { Endpoints } from '../network';
import { getAllBanners } from '../services/banner';
import { getAllShortBookListApi } from '../services/Library/Books';
import { getAllProducts } from '../services/Products';
import { BundleData } from '../utils/interfaces/Products/bundles';
import { User } from '../utils/interfaces/User';

export const generateUrlForImageBanner = (
  baseUrl: string,
  page: string,
  type: string
): string => {
  const url = `${baseUrl}api/banner/images/${page}${type}`;
  return url;
};

const Home: React.FC = () => {
  // const [currentFilter, setCurrentFilter] = useState<number>(1);
  const [shortBookLoading, setShortBookLoading] = useState(false);
  const router = useRouter();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const keyWord = router.query.search;
  const [bundleLoading, setBundleLoading] = useState(false);
  const [search, setSearch] = useState<string>();
  const userData: User | undefined = useRecoilValue(user);

  const [token, setToken] = useState('');
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const [banners, setBanners] = useState<BookProps[]>([]);
  const [bundleData, setBundleData] = useState<BundleData[]>([]);

  const screens = useBreakpoint();
  const [shortBooks, setShortBooks] = useState<
    {
      _id: string;
      coverImageUrl: string;
      title: string;
      externalLink: string;
      slug: string;
      type: string;
      totalPages: number;
      isExternalLink: boolean;
    }[]
  >([]);
  const bannerPage = useRecoilValue(bannerPages);
  // const handleFilterChange = (index: number) => {
  //   setCurrentFilter(index);
  // };
  // console.log(shortBooks);

  const fetchData = async () => {
    try {
      const token = await getLoginToken();
      setToken(token as string);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  const fetchBanners = async () => {
    try {
      const pageId = await getIdByName(bannerPage, 'home');

      if (pageId) {
        const response = await getAllBanners(pageId);

        setBanners(
          response?.data?.map((banner: any) => ({
            id: banner?._id,
            name: banner?.heading,
            webFlowLink: banner?.link,
            desc: banner?.desc,
            imageLG: generateUrlForImageBanner(
              `${process?.env.NEXT_PUBLIC_API_READER_URL}/`,
              `${banner?._id}/`,
              `webLarge`
            ),
            imageSM: generateUrlForImageBanner(
              `${process?.env.NEXT_PUBLIC_API_READER_URL}/`,
              `${banner?._id}/`,
              `webMobile`
            ),
            slug: banner?.slug === 'true',
          }))
        );
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };
  // fetchBanners();

  const generateUrlForImage = (
    baseUrl: string,
    endpoint: string,
    id: string
  ): string => {
    const encodedId = encodeURIComponent(id);
    const url = `${baseUrl}${endpoint}${encodedId}`;
    return url;
  };

  const handleBundleDetails = async (title?: string) => {
    try {
      setBundleLoading(true);
      const data = await getAllProducts(1, 7, undefined, title);

      const formattedData = formatBundleData(data?.data);

      setBundleData((prevData) => {
        const uniqueData = prevData?.filter(
          (prevItem) =>
            !formattedData.some(
              (newItem) => newItem.hardCopyId === prevItem.hardCopyId
            )
        );
        return [...uniqueData, ...formattedData];
      });
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setBundleLoading(false);
    }
  };

  const formatBundleData = (rawData: any[]): BundleData[] => {
    return rawData.map((bundle) => ({
      image: bundle?.thumbnail_image || '',
      softCopyId: bundle?.digital_pair?.id || '',
      hardCopyId: bundle?.id || '',
      heading: bundle?.name || '',
      slug: bundle?.slug || '',
      softCopyHeading: bundle?.digital_pair?.name || 0,
      desc: bundle?.description || '',
      currency: bundle?.currency_symbol || '',
      actualPriceHardCopy: bundle?.stroked_price || 0,
      actualPriceSoftCopy:
        (bundle?.digital_pair && bundle.digital_pair?.stroked_price) || 0,
      hardCopyPrice: bundle?.main_price || 0,
      softCopyPrice:
        (bundle?.digital_pair && bundle?.digital_pair.main_price) || 0,
      type: bundle?.category || '',
      external_link: bundle?.external_link || '',
      products: bundle?.bundle_products,
    }));
  };

  const handleGetAllShortBooks = async (title?: string) => {
    try {
      setShortBookLoading(true);
      const response = await getAllShortBookListApi(title);
      const { data } = response;

      const formattedBooks = data
        ?.map((book: any) => ({
          _id: book?._id,
          coverImageUrl: generateUrlForImage(
            `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
            `${Endpoints.getCoverImagesForBooks}/`,
            `${book?._id}`
          ),
          title: book?.title,
          slug: book?.slug,
          type: book?.type,
          totalPages: book?.totalPages,
          isExternalLink: book?.isExternalLink,
        }))
        .filter((book: any) => book.type === 'short');
      setShortBooks(formattedBooks);
    } catch (error: any) {
      notify(error, 'error');
    } finally {
      setShortBookLoading(false);
    }
  };

  const handleSearch = async (keyword: string) => {
    if (keyword !== undefined) {
      setSearch(keyword);
      handleGetAllShortBooks(keyword);
      handleBundleDetails(keyword);
    } else {
      handleGetAllShortBooks();
      handleBundleDetails();
    }
  };

  const delayedSearch = debounce((keyword: string) => {
    handleSearch(keyword);
    if (keyWord) {
      const eventParams: EventDetails = {
        event_name: 'search_keyword',
        source: 'global',
        source_type: 'search',
        source_id: null,
        sub_source: null,
        sub_source_id: null,
        unit: 'string',
        value: keyword,
      };

      logEvent({
        eventDetails: eventParams,
        userData,
        pathname: router.pathname,
      });
    }
  }, 500);

  useEffect(() => {
    delayedSearch(searchKeyword);
    return () => {
      delayedSearch.cancel();
      setSearch(undefined);
    };
  }, [searchKeyword]);

  useEffect(() => {
    handleGetAllShortBooks();
    handleBundleDetails();
    fetchData();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [isTokenChanged]);

  useEffect(() => {
    if (bannerPage.length >= 1) {
      fetchBanners();
    }
  }, [bannerPage]);

  useEffect(() => {
    setSearchKeyword(keyWord as string);
  }, [keyWord]);

  const countBooksAndCourses = (products: any[]) => {
    // Initialize counters
    let bookCount = 0;
    let courseCount = 0;

    // Iterate through the array and count books and recorded courses
    products?.forEach((product: any) => {
      if (product?.category === 'Book') {
        bookCount++;
      } else if (product.category === 'Recorded Course') {
        courseCount++;
      }
    });

    return {
      bookCount,
      courseCount,
    };
  };

  return (
    <>
      <Head>
        <title>
          Books Publisher in India for Finance, Marketing and more. | ZebraLearn
        </title>
        <meta
          name='description'
          content='Discover best books publisher in India specializing in non-fiction books. ZebraLearn offers cutting-edge design & technology insights, simplifying complex topics.
          '
        />
      </Head>

      <div className='  flex flex-col gap-y-2 '>
        {/* <ShouldRender check={router.isReady}> */}
        <div className='mt-12 lg:mt-20 lg:px-12 h-min'>
          <HeroSection books={banners} />
        </div>
        {/* </ShouldRender> */}

        <div className='flex flex-col gap-y-5 mt-7 pb-8 md:pb-16'>
          <PaginatedBookSlider
            categoryId={null}
            categoryName='Featured Books'
            search={search}
            isHomePage={true}
            order='asc'
            featured={true}
          />
          <PaginatedBookSlider
            categoryId={null}
            categoryName='Read Zebralearn Bestsellers'
            search={search}
            isHomePage={true}
            order='asc'
            categoryIdFilter={
              process.env.NEXT_PUBLIC_APP_ENV === 'staging'
                ? '66d95bde2da439ae0a02b8b0'
                : '66c6db9e104ce84c7e2a8956'
            }
          />
          <PaginatedBookSlider
            categoryId={
              process.env.NEXT_PUBLIC_APP_ENV === 'staging'
                ? '66d95bde2da439ae0a02b8b0'
                : '66c6db9e104ce84c7e2a8956'
            }
            categoryName='CBSE Books For Class 6 - 9'
            search={search}
            isHomePage={true}
          />

          <div className=''>
            <ShouldRender check={shortBooks?.length || shortBookLoading}>
              <HomepageSlider
                loading={!!shortBooks?.length}
                heading='Quick Books to Level up'
              >
                {!shortBookLoading
                  ? shortBooks?.map((item, index) => (
                      <Link
                        className='group cursor-pointer p-1 md:p-1.5 no-select'
                        key={index}
                        href={`/books/${item.slug}?swp=${item?.isExternalLink}`}
                      >
                        <div className='relative group-hover:scale-105 transition-transform duration-500 transform-origin-center'>
                          <Badge.Ribbon
                            placement='start'
                            className='customRibbon text-white'
                            text={
                              <div className='text-xs font-medium'>Free</div>
                            }
                            style={{ color: 'white' }}
                          >
                            <div className='relative'>
                              <span>
                                <ShouldRender check={item?.coverImageUrl}>
                                  <Image
                                    src={item.coverImageUrl}
                                    alt={item.title}
                                    width={200}
                                    height={200}
                                    className='rounded-sm -z-10 md:rounded-md max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                                  />
                                </ShouldRender>
                              </span>
                            </div>
                          </Badge.Ribbon>
                        </div>
                        <div className='mt-2'>
                          <span className='text-textGrayColor text-xs md:text-sm'>
                            {item?.totalPages} Pages
                          </span>
                          <div className='text-white text-sm xl:text-base font-medium w-full max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'>
                            <ParserV2
                              isPopOver={false}
                              text={item?.title}
                              maxWords={screens.xl ? 10 : screens.lg ? 6 : 4}
                            />
                          </div>
                        </div>
                      </Link>
                    ))
                  : Array.from({ length: 10 }).map((_, index) => (
                      <span
                        key={index}
                        className='group cursor-pointer flex flex-col gap-y-1 p-1 md:p-1.5 bg-transparent no-select '
                      >
                        <Skeleton.Button
                          style={{
                            width: '200px',
                            height: screens.xl ? '250px' : '150px',
                          }}
                          active={true}
                          className='rounded-sm md:rounded-md  transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[200px]'
                        />
                        <Skeleton.Button
                          style={{
                            width: screens.xl
                              ? '100px'
                              : screens.lg
                              ? '50px'
                              : '20px',
                            height: '8px',
                            fontSize: '7px',
                          }}
                          active={true}
                          className='rounded-sm  transition-transform transform  duration-500 '
                        />
                        <Skeleton.Button
                          style={{
                            // width: screens.xl ? '100px' : screens.lg ? '50px' : '20px',
                            height: '12px',
                            fontSize: '7px',
                          }}
                          active={true}
                          className='rounded-sm w-full  -translate-y-1 transition-transform transform  duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[220px]'
                        />
                      </span>
                    ))}
              </HomepageSlider>
            </ShouldRender>
          </div>
          <PaginatedBookSlider
            categoryId={null}
            categoryName='Shelf loading soon: Magic in making'
            bookType='comingSoon'
            search={search}
            isHomePage={true}
          />

          <div className=''>
            <PaginatedSchoolSlider
              categoryId={null}
              search={search}
              categoryName='Start with Schools, your daily reads'
            />
          </div>

          <div className=''>
            <PaginatedCoursesSlider
              categoryId=''
              search={search}
              token={token}
              name='best courses from industry leaders'
            />
          </div>
          <ShouldRender check={bundleData?.length || bundleLoading}>
            <div className='mt-2'>
              <HomepageSlider
                loading={!!bundleData?.length}
                heading='Explore Curated Learning Bundles'
              >
                <span className='flex '>
                  {!bundleLoading
                    ? bundleData?.map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => {
                              if (window !== undefined && item?.external_link) {
                                window.open(item?.external_link, '_blank');
                              }
                            }}
                            className='flex flex-col gap-y-4  no-select p-1 md:p-1.5  cursor-pointer'
                          >
                            <Image
                              src={item.image}
                              alt={item.heading}
                              width={430}
                              height={280}
                              className='rounded-md transition-transform transform hover:scale-105 duration-500 min-w-[170px] lg:min-w-[220px] max-w-[220px] lg:max-w-[320px] h-[220px] lg:h-[320px]'
                              style={{ objectFit: 'cover' }}
                            />
                            <div>
                              <span className='flex text-base text-textGrayColor'>
                                <ShouldRender
                                  check={
                                    countBooksAndCourses(item?.products)
                                      .bookCount
                                  }
                                >
                                  {
                                    countBooksAndCourses(item?.products)
                                      .bookCount
                                  }{' '}
                                  Book{' '}
                                </ShouldRender>
                                <ShouldRender
                                  check={
                                    countBooksAndCourses(item?.products)
                                      .courseCount
                                  }
                                >
                                  +{' '}
                                  {
                                    countBooksAndCourses(item?.products)
                                      .courseCount
                                  }{' '}
                                  Course
                                </ShouldRender>
                              </span>

                              <span className='text-white text-sm xl:text-base font-medium'>
                                {item.heading}
                              </span>
                            </div>
                          </span>
                        );
                      })
                    : Array.from({ length: 6 }).map((_, index) => (
                        <span
                          key={index}
                          className='group pb-10 cursor-pointer p-1 md:p-1.5 bg-transparent no-select '
                        >
                          <Skeleton.Button
                            style={{
                              width: screens.lg ? '430px' : '200px',
                              height: screens.xl
                                ? '280px'
                                : screens.lg
                                ? '200px'
                                : '150px',
                            }}
                            active={true}
                            className='rounded-sm md:rounded-md transition-transform transform hover:scale-105 duration-500 min-w-[170px] lg:min-w-[220px] max-h-[220px] lg:max-h-[320px]'
                          />
                        </span>
                      ))}
                </span>
              </HomepageSlider>
            </div>
          </ShouldRender>
        </div>
      </div>
    </>
  );
};

export default Home;
