export const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours === 0 && minutes === 0) {
    return `${remainingSeconds} sec`;
  } else if (hours === 0) {
    return `${minutes} min `;
  } else if (minutes === 0 && remainingSeconds === 0) {
    return `${hours} hr`;
  } else if (minutes === 0) {
    return `${hours} hr`;
  } else if (remainingSeconds === 0) {
    return `${hours} hr ${minutes} min`;
  } else {
    return `${hours} hr ${minutes} min`;
  }
};
