import { Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React, { useEffect, useState } from 'react';

import CoursesCard from '../CoursesCard';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { formateErrorObject } from '../../shared/formateErrorObject';
import { notify } from '../../shared/notify';
import HomepageSlider from '../../V2/HomePage/HomepageSlider';
import { ICourse } from '../../../pages/courses';
import { getAllCourse } from '../../../services/Courses';
import { PaginationProps } from '../../../utils/interfaces/Orders';

interface Props {
  categoryName?: string;
  categoryId: string;
  token: string;
  name?: string;
  search?: string;
}

const PaginatedCoursesSlider: React.FC<Props> = ({
  categoryId,
  categoryName,
  token,
  name,
  search,
}) => {
  const [coursesCardDataPagination, setCoursesDataPagination] = useState<
    ICourse[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [isPaginateShift, setIsPaginateShift] = useState(true);

  const [paginationData, setPaginationData] = useState<PaginationProps>();
  const screens = useBreakpoint();

  const handleFetchCategoryWise = async () => {
    try {
      setLoading(true);
      const response = await getAllCourse(
        String(page),
        categoryName === 'All' ? null : categoryName,
        search
      );
      const { data } = response;

      const formattedCourses = (data ?? response?.data)?.map((course: any) => ({
        id: course?.id,
        course_image_url: course?.course_image_url,
        name: course?.name,
        slug: course?.slug,
        duration: course?.totalVideoDuration,
      }));

      if (page === 1) {
        setCoursesDataPagination(formattedCourses);
      } else {
        setCoursesDataPagination((prevData) => [
          ...prevData,
          ...formattedCourses,
        ]);
      }

      setPaginationData({
        total: response?.extra?.totalDataLength,
      });
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchCategoryWise();
  }, [page, categoryId, search]);

  const handlePagination = () => {
    if (
      paginationData &&
      coursesCardDataPagination.length < paginationData?.total
    )
      setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <ShouldRender check={coursesCardDataPagination.length || loading}>
        <HomepageSlider
          heading={name ?? (categoryName as string)}
          setIsPaginateShift={setIsPaginateShift}
          isPaginateShift={isPaginateShift}
          onPagination={handlePagination}
          loading={!!coursesCardDataPagination}
        >
          <CoursesCard
            courses={coursesCardDataPagination}
            loading={loading}
            token={token}
          />
          <ShouldRender check={loading || isPaginateShift}>
            {Array.from({ length: 5 }).map((_, index) => (
              <span
                key={index}
                className='group cursor-pointer p-1 md:p-1.5 bg-transparent no-select '
              >
                <Skeleton.Button
                  style={{
                    width: screens.xl
                      ? '200px'
                      : screens.lg
                      ? '150px'
                      : '120px',
                    height: screens.xl
                      ? '200px'
                      : screens.lg
                      ? '150px'
                      : '120px',
                  }}
                  active={true}
                  className='rounded-sm md:rounded-md transition-transform transform hover:scale-105 duration-500 max-w-[120px] lg:max-w-[140px] xl:max-w-[220px]'
                />
              </span>
            ))}
          </ShouldRender>
        </HomepageSlider>
      </ShouldRender>
    </>
  );
};

export default PaginatedCoursesSlider;
